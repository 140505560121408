import React, { useEffect, useState } from "react";

import { IonItem, IonToggle } from "@ionic/react";
import { Marker, useMap, Map as ReactMapGLMap, MapProvider } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";


interface IProps {
	setCoordinates: (coordinates: [number, number]) => void;
	coordinates?: [number, number];
}

type MapStyle = "map" | "satellite";

export const Map: React.FC<IProps> = (props) => {
	const { setCoordinates, coordinates } = props;
	const [mapStyle, setMapStyle] = useState<MapStyle>("satellite");
	return (
		<MapProvider>
			<MapEffects coordinates={coordinates} />
			<ReactMapGLMap
				id="map"
				mapStyle={getStyleUrl(mapStyle)}
				mapboxAccessToken={AT}
				initialViewState={{
					longitude: (coordinates && coordinates[0]) ?? -1,
					latitude: (coordinates && coordinates[1]) ?? 40,
					zoom: coordinates ? 7 : 4
				}}
				style={{ width: "100%", height: 300 }}
				onClick={(ev) => {
					setCoordinates([ev.lngLat.lng, ev.lngLat.lat]);
				}}
			>
				{coordinates && (
					<Marker
						key={"marker"}
						longitude={coordinates[0]}
						latitude={coordinates[1]}
						onClick={(e) => {
							e.originalEvent.stopPropagation();
						}}
						color={"var(--ion-color-primary)"}
						scale={1}
						style={{
							cursor: "pointer",
						}}
					/>
				)}
			</ReactMapGLMap>
			<LayerSelector mapStyle={mapStyle} setMapStyle={setMapStyle} />
		</MapProvider>
	);
};

interface LayerSelectorProps {
	mapStyle: MapStyle,
	setMapStyle: React.Dispatch<React.SetStateAction<MapStyle>>
}
const LayerSelector: React.FC<LayerSelectorProps> = (props) => {
	const { mapStyle, setMapStyle } = props;
	return <IonItem>
		<IonToggle onIonChange={() => setMapStyle(v => v === "map" ? "satellite" : "map")} checked={mapStyle === "satellite"} mode="md" justify="end">
			Vista satélite
		</IonToggle>
	</IonItem>
}

interface MapEffectsProps {
	coordinates?: [number, number]
}
const MapEffects: React.FC<MapEffectsProps> = (props) => {
	const { coordinates } = props;
	const { map } = useMap()

	useEffect(() => {
		const currentZoom = map?.getZoom();
		if (coordinates) {
			map?.easeTo({ zoom: currentZoom && currentZoom > 17 ? currentZoom : 17, center: coordinates, duration: 1000 })
		}
	}, [map, coordinates?.toString()])
	return <React.Fragment />
}

const getStyleUrl = (style: MapStyle) => {
	switch (style) {
		case "satellite":
			return "mapbox://styles/mapbox/satellite-v9";
		default:
			return "mapbox://styles/dhub/cl2ypoavr000z14n5af53rj77";
	}
}

const AT = "pk.eyJ1IjoiZGh1YiIsImEiOiJjbGUyczIyODQwMXdrM3dseG53MnMxaXI4In0.FYGuulRoBvKZ56vsbIXpzA";

