import React, { useEffect, useState } from "react";

import {
	IonContent,
	IonFab,
	IonFabButton,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonPage,
	IonSpinner,
	IonText,
} from "@ionic/react";
import { addOutline, filterOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Header from "components/common/Header";
import { ConfirmDeleteModal } from "components/modals/ConfirmDeleteModal";
import CreateAssetModal from "components/modals/CreateAsset/CreateAssetModal";
import FilterAssetModal from "components/modals/FilterAsset/FilterAssetModal";
import SyncModal from "components/modals/SyncModal";
import Asset from "models/Asset";
import FormRecord from "models/FormRecord";
import Project from "models/Project";
import { useAppSelector } from "store";
import { getSyncStatus, SyncStatus } from "utils/sync/isSynced";

import { AssetViewState, AssetWithSyncStatus } from "./types";
import { groupLocalAssets, groupRemoteAssets } from "./utils/groupAssets";
import AssetGroup from "./components/AssetGroup";
import { getLocalAssetsToDisplay, getRemoteAssetsToDisplay } from "./utils/getAssetsToDisplay";
import DuplicateAssetModal from "components/modals/DuplicateAssetModal";

export interface ActiveSyncProps {
	id: string;
	title: string;
	skipBackgroundSync?: boolean;
}

const AssetsView: React.FC = () => {
	const { t, i18n } = useTranslation();
	const { projectRef } = useParams<{ projectRef: string }>();
	const [state, setState] = useState<AssetViewState>({
		numberOfProjects: 1,
		allLocalAssets: { items: [], total: 0 },
		allRemoteAssets: { items: [], total: 0 },
		localAssets: {
			discarded_local_assets: [],
			draft_local_assets: [],
			rejected_local_assets: [],
			in_progress_local_assets: [],
			pending_local_assets: [],
			validated_local_assets: [],
		},
		remoteAssets: {
			remote_assets: [],
			rejected_remote_assets: [],
			discarded_remote_assets: [],
		},
		remotePages: 1,
		loading: true,
	});
	const [deliveryFeatureActive, setDeliveryFeatureActive] = useState(false);
	const [canCreate, setCanCreate] = useState(false);
	const [activeSync, setActiveSync] = useState<ActiveSyncProps>();
	const [syncProjectOpen, setSyncProjectOpen] = useState(false);
	const [visibleRemoteAssets, setVisibleRemoteAssets] = useState<AssetWithSyncStatus[]>([]);

	const remoteSlice = useAppSelector((state) => state.remote);
	const isOnline = useAppSelector((state) => state.connection.isOnline);
	const assetFilterState = useAppSelector((state) => state.assetFilter);

	const [openCreateAssetModal, setCreateAssetModal] = useState(false);
	const [openFilterAssetModal, setFilterAssetModal] = useState(false);
	const [assetToDelete, setAssetToDelete] = useState<string | undefined>();
	const [assetToDuplicate, setAssetToDuplicate] = useState<Asset | undefined>();

	useEffect(() => {
		Project.hasFeature(projectRef, "can-deliver").then((result) => {
			setDeliveryFeatureActive(result);
		});
		Project.hasFeature(projectRef, "can-create").then((result) => {
			setCanCreate(result);
		});
	}, [projectRef]);

	useEffect(() => {
		let isCancelled = false;
		const initialize = async () => {
			//setState((state) => ({ ...state, loading: true }));
			const numberOfProjects = await Project.getAll().then((projs) => projs.length);
			const project = await Project.get(projectRef);
			const formRecords = await FormRecord.byProjectRef(projectRef); // Don't include deleted or inactive records
			const localAssets = await getLocalAssetsToDisplay(
				projectRef,
				formRecords,
				project.configuration.configuration_filter || [],
				assetFilterState,
				remoteSlice.data,
			);
			const remoteAssets = await getRemoteAssetsToDisplay(
				projectRef,
				project.configuration.configuration_filter || [],
				assetFilterState,
				remoteSlice.data,
				state.allRemoteAssets.total,
			).catch((err) => {
				console.log(err);
				return { total: 0, filteredTotal: 0, items: [] };
			});
			const projectSyncStatus =
				localAssets.total && remoteSlice.thunkState !== "idle"
					? await getSyncStatus(remoteSlice.data, projectRef, undefined, true)
					: undefined;
			if (isCancelled) return;
			const groupedLocal = groupLocalAssets(localAssets.items);
			const groupedRemote = groupRemoteAssets(remoteAssets.items);
			setState({
				numberOfProjects,
				project,
				formRecords,
				allLocalAssets: localAssets,
				allRemoteAssets: remoteAssets,
				localAssets: groupedLocal,
				remoteAssets: groupedRemote,
				loading: false,
				projectSyncState: projectSyncStatus,
				remotePages: 1,
			});
			const numInitial = Math.max(100 - localAssets.items.length, 20);
			setVisibleRemoteAssets(groupedRemote.remote_assets.slice(0, numInitial));
		};
		initialize();
		return () => {
			isCancelled = true;
		};
	}, [projectRef, remoteSlice, assetFilterState]);

	useEffect(() => {
		setState((state) => ({ ...state, loading: true }));
	}, [assetFilterState])

	const handleLoadPage = async (ev: CustomEvent) => {
		const target = ev.target as HTMLIonInfiniteScrollElement;
		const nextPage = state.remotePages + 1;
		setVisibleRemoteAssets(state.remoteAssets.remote_assets.slice(0, nextPage * 100))
		console.log("Loading page", nextPage)
		state.remotePages += 1;
		target.complete();
	};

	const buildRouterLink = (asset: Asset, syncStatus?: SyncStatus) => {
		if (syncStatus === "remote") return undefined;
		const assetRecords = state.formRecords?.filter((it) => it.asset_id === asset.id);
		return state.project && assetRecords?.length === 1
			? `/${projectRef}/${asset.id}/${assetRecords[0].id}`
			: `/${projectRef}/${asset.id}/`;
	};

	const totalFilteredAssets =
		state.allLocalAssets.items.length + (state.allRemoteAssets.filteredTotal ?? state.allRemoteAssets.total);
	const totalAssets = state.allLocalAssets.total + state.allRemoteAssets.total;

	return (
		<IonPage>
			<Header
				title={state.project?.name || "..."}
				syncState={state.projectSyncState !== "remote" ? state.projectSyncState : undefined}
				backUrl={state.numberOfProjects === 1 ? undefined : "/"}
				syncDetails={
					state.projectSyncState && state.projectSyncState !== "remote"
						? {
							variant: state.projectSyncState || "synced",
							openSyncModal: () => setSyncProjectOpen(true),
						}
						: undefined
				}
			/>
			<IonContent forceOverscroll={false} style={{ paddingBottom: "5rem" }}>
				<IonList>
					<IonListHeader lines="full" style={{ display: "flex", justifyContent: "space-between" }}>
						<IonLabel data-testid="assetListLabel">{i18n.format(t("asset_list"), "capitalize")}</IonLabel>
						<IonLabel
							style={{
								color: "var(--ion-color-medium-tint)",
								textAlign: "end",
								paddingRight: "1.25rem",
								maxWidth: "4.25rem",
							}}
						>
							{i18n.format(t("synced"), "capitalize")}
						</IonLabel>
					</IonListHeader>
					{!state.loading && (<React.Fragment>
						<AssetGroup
							label={"draft_local_assets"}
							items={state.localAssets.draft_local_assets}
							deliveryFeatureActive={deliveryFeatureActive}
							isOnline={isOnline}
							buildRouterLink={buildRouterLink}
							setActiveSync={setActiveSync}
							setAssetToDelete={setAssetToDelete}
							setAssetToDuplicate={setAssetToDuplicate}
						/>
						<AssetGroup
							label={"in_progress_local_assets"}
							items={state.localAssets.in_progress_local_assets}
							deliveryFeatureActive={deliveryFeatureActive}
							isOnline={isOnline}
							buildRouterLink={buildRouterLink}
							setActiveSync={setActiveSync}
							setAssetToDelete={setAssetToDelete}
							setAssetToDuplicate={setAssetToDuplicate}
						/>
						<AssetGroup
							label={"rejected_local_assets"}
							items={state.localAssets.rejected_local_assets}
							deliveryFeatureActive={deliveryFeatureActive}
							isOnline={isOnline}
							buildRouterLink={buildRouterLink}
							setActiveSync={setActiveSync}
							setAssetToDelete={setAssetToDelete}
							setAssetToDuplicate={setAssetToDuplicate}
						/>
						<AssetGroup
							label={"rejected_remote_assets"}
							items={state.remoteAssets.rejected_remote_assets}
							deliveryFeatureActive={deliveryFeatureActive}
							isOnline={isOnline}
							buildRouterLink={buildRouterLink}
							setActiveSync={setActiveSync}
							setAssetToDelete={setAssetToDelete}
							setAssetToDuplicate={setAssetToDuplicate}
						/>
						<AssetGroup
							label={"pending_local_assets"}
							items={state.localAssets.pending_local_assets}
							deliveryFeatureActive={deliveryFeatureActive}
							isOnline={isOnline}
							buildRouterLink={buildRouterLink}
							setActiveSync={setActiveSync}
							setAssetToDelete={setAssetToDelete}
							setAssetToDuplicate={setAssetToDuplicate}
						/>
						<AssetGroup
							label={"validated_local_assets"}
							items={state.localAssets.validated_local_assets}
							deliveryFeatureActive={deliveryFeatureActive}
							isOnline={isOnline}
							buildRouterLink={buildRouterLink}
							setActiveSync={setActiveSync}
							setAssetToDelete={setAssetToDelete}
							setAssetToDuplicate={setAssetToDuplicate}
						/>
						<AssetGroup
							label={"discarded_local_assets"}
							items={state.localAssets.discarded_local_assets}
							deliveryFeatureActive={deliveryFeatureActive}
							isOnline={isOnline}
							buildRouterLink={buildRouterLink}
							setActiveSync={setActiveSync}
							setAssetToDelete={setAssetToDelete}
							setAssetToDuplicate={setAssetToDuplicate}
						/>
						<AssetGroup
							label={"remote_assets"}
							items={visibleRemoteAssets}
							deliveryFeatureActive={deliveryFeatureActive}
							isOnline={isOnline}
							buildRouterLink={buildRouterLink}
							setActiveSync={setActiveSync}
							setAssetToDelete={setAssetToDelete}
							setAssetToDuplicate={setAssetToDuplicate}
						/>
						<AssetGroup
							label={"discarded_remote_assets"}
							items={state.remoteAssets.discarded_remote_assets}
							deliveryFeatureActive={deliveryFeatureActive}
							isOnline={isOnline}
							buildRouterLink={buildRouterLink}
							setActiveSync={setActiveSync}
							setAssetToDelete={setAssetToDelete}
							setAssetToDuplicate={setAssetToDuplicate}
						/>
					</React.Fragment>)}
					{remoteSlice.thunkState === "rejected" && (
						<IonItem lines="none">
							<IonText
								style={{ width: "100%", textAlign: "center", fontSize: ".75rem", fontStyle: "italic", color: "gray" }}
							>
								{i18n.format(t("couldNotFetchRemoteAssetsMsg"), "capitalize")}
							</IonText>
						</IonItem>
					)}
				</IonList>
				{(state.loading || ["idle"].includes(remoteSlice.thunkState)) && isOnline ? (
					<IonItem lines="none">
						<IonSpinner name="lines-sharp-small" color="medium" style={{ margin: "auto" }} />
					</IonItem>
				) : (
					<IonInfiniteScroll onIonInfinite={handleLoadPage}>
						<IonInfiniteScrollContent loadingText="Please wait..." loadingSpinner="lines-sharp" />
					</IonInfiniteScroll>
				)}
			</IonContent>
			{state.project?.configuration.configuration_filter.length ? (
				<IonFab vertical="bottom" horizontal="start">
					<IonFabButton onClick={() => setFilterAssetModal(true)} color="gray600">
						<IonIcon src={filterOutline} />
					</IonFabButton>
				</IonFab>
			) : (
				<React.Fragment />
			)}
			{totalFilteredAssets !== totalAssets && (
				<div
					className="number-of-filtered-items-tag"
					style={{
						position: "absolute",
						left: "3rem",
						color: "var(--ion-color-primary)",
						bottom: "1.5rem",
						borderRadius: "1rem",
						backgroundColor: "var(--ion-color-gray200)",
						minWidth: "5rem",
						height: "2rem",
						textAlign: "center",
						lineHeight: "2rem",
						fontSize: "0.8rem",
						boxShadow: "0.1rem 0.1rem 0.1rem 0.1rem var(--ion-color-light-shade)",
						paddingLeft: "1.5rem",
						paddingRight: "1rem",
					}}
				>{`${totalFilteredAssets} / ${totalAssets}`}</div>
			)}
			{canCreate && isOnline && (
				<IonFab vertical="bottom" horizontal="end">
					<IonFabButton style={{ marginRight: "0.5rem" }} onClick={() => setCreateAssetModal(true)} color="gray600">
						<IonIcon src={addOutline} />
					</IonFabButton>
				</IonFab>
			)}
			{isOnline && (
				// Asset-specific sync
				<SyncModal
					isOpen={activeSync !== undefined}
					projectRef={projectRef}
					assetId={activeSync?.id}
					title={activeSync?.title || ""}
					onClose={() => {
						setActiveSync(undefined);
					}}
					skipBackgroundSync={activeSync?.skipBackgroundSync}
				/>
			)}
			{isOnline && (
				// Project-specific sync
				<SyncModal
					isOpen={syncProjectOpen}
					projectRef={projectRef}
					title={state.project?.name || ""}
					onClose={() => {
						setSyncProjectOpen(false);
					}}
				/>
			)}
			{isOnline && (
				<CreateAssetModal
					isOpen={openCreateAssetModal}
					projectRef={projectRef}
					assetId={activeSync?.id}
					onClose={() => {
						setCreateAssetModal(false);
					}}
				/>
			)}
			<FilterAssetModal
				isOpen={openFilterAssetModal}
				deliveryFeatureActive={deliveryFeatureActive}
				onClose={() => {
					setFilterAssetModal(false);
				}}
			/>
			{isOnline && assetToDuplicate !== undefined && (
				<DuplicateAssetModal
					isOpen={assetToDuplicate !== undefined}
					projectRef={projectRef}
					onClose={() => {
						setAssetToDuplicate(undefined);
					}}
					asset={assetToDuplicate}
				/>
			)}
			{assetToDelete !== undefined && (
				<ConfirmDeleteModal
					isOpen={assetToDelete !== undefined}
					projectRef={projectRef}
					assetId={assetToDelete}
					onClose={() => {
						setAssetToDelete(undefined);
					}}
				/>
			)}
		</IonPage>
	);
};

export default AssetsView;
