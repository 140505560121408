import React, { useEffect, useState } from "react";

import { getResults } from "api/results";
import { ITEResult } from "interfaces/ITEResult";
import { IonSpinner, IonText } from "@ionic/react";
import { ITEResultIndicator } from "./ITEResultIndicator";

interface IProps {
	recordIds: string[]
	isIteBasica?: boolean
}

export const ITEResults: React.FC<IProps> = (props) => {
	const { recordIds, isIteBasica } = props;
	const [results, setResults] = useState<ITEResult>();

	useEffect(() => {
		let isCancelled = false;
		getResults(recordIds).then((res) => !isCancelled && setResults(res));
		return () => { isCancelled = true };
	}, [recordIds.join(",")])

	return <div style={{ width: "100%" }}>
		<IonText style={{ marginBottom: "0.5rem" }}> Resultados preliminares de la ITE: </IonText>
		{!results
			? <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
				<IonSpinner name="crescent" />
			</div>
			//: <div style={{ display: "flex", justifyContent: "start", gap: ".5rem" }}>
			: <div style={{ display: "flex", flexDirection: "column", gap: "1rem", margin: "1rem" }}>
				<div style={{ display: "flex", margin: 0, justifyContent: "space-between" }}>
					Estado de conservación <ITEResultIndicator value={results.nec} type="nec" />
				</div>
				{!isIteBasica ?
					<div style={{ display: "flex", margin: 0, justifyContent: "space-between" }}>
						Eficiencia energética <ITEResultIndicator value={results.nee} type="nee" />
					</div> : <React.Fragment />}
				{!isIteBasica ?
					<div style={{ display: "flex", margin: 0, justifyContent: "space-between" }}>
						Accesibilidad<ITEResultIndicator value={results.nae} type="nae" />
					</div> : <React.Fragment />}
				<div style={{ display: "flex", margin: 0, justifyContent: "space-between" }}>
					Cumplimiento de normativa <ITEResultIndicator value={results.ncn} type="ncn" />
				</div>
			</div>
		}
	</div >
}
