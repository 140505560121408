import React, { useState } from "react";

import {
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonPage,
	IonSpinner,
	IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { lock } from "assets/icons";
import { updateStatus } from "api/records";
import Header from "components/common/Header";
import { ConfirmDeliveryModal } from "components/modals/ConfirmDeliveryModal";
import { ConfirmBIMModelDeliveryModal } from "components/modals/ConfirmBIMModelDeliveryModal";
import SyncModal from "components/modals/SyncModal";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord from "models/FormRecord";
import Project from "models/Project";
import { useAppDispatch, useAppSelector } from "store";
import { performBackgroundSyncThunk } from "store/slices/remote/thunks";
import { SyncStatus } from "utils/sync/isSynced";
import { DeliveryStatusButtonList } from "views/RecordsView/DeliveryStatusButtonList";
import { CompletitionStatusIcon } from "views/RecordsView/CompletitionStatusIcon";
import { useRecordViewUpdateState } from "./useRecordViewUpdateState";
import { LinkList } from "./LinkList";
import { getBIMModelDelivered, putBIMModelDelivered } from "api/bimStatus";

export interface IState {
	project?: Project;
	asset?: Asset;
	forms: Form[];
	records: FormRecord[];
	assetSyncState?: SyncStatus;
	loading: boolean;
}

const RecordsView: React.FC = () => {
	const { projectRef, assetId } = useParams<{
		projectRef: string;
		assetId: string;
	}>();
	const { t, i18n } = useTranslation();
	const [state, setState] = useState<IState>({
		forms: [],
		records: [],
		loading: true,
	});
	const dispatch = useAppDispatch();
	const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
	const [isOpenConfirmBIMModelModal, setOpenConfirmBIMModelModal] = useState<boolean>(false);
	const [bimModelDeliverMessage, setBimModelDeliverMessage] = useState<string | undefined>();
	const [hasModelBim, setHasModelBim] = React.useState(false);
	const [isOpenSyncModal, setOpenSyncModal] = useState<boolean>(false);
	const [syncOpen, setSyncOpen] = useState(false);
	const remoteSlice = useAppSelector((state) => state.remote);
	const isOnline = useAppSelector((state) => state.connection.isOnline);

	useRecordViewUpdateState(assetId, projectRef, setState, remoteSlice.data);

	React.useEffect(() => {
		const fetchBIMDelivered = async () => {
			const response = await getBIMModelDelivered(assetId);
			if (response) {
				setHasModelBim(response);
			}
		};
		fetchBIMDelivered();
	}, []);
	return (
		<IonPage>
			<Header
				title={state.asset?.name || "..."}
				syncState={state.assetSyncState}
				backUrl={`/${projectRef}/`}
				syncDetails={{ variant: state.assetSyncState || "synced", openSyncModal: () => setSyncOpen(true) }}
				canBeDeleted={state.asset && state.asset.can_be_deleted && !state.asset.is_deleted}
			/>
			<IonContent forceOverscroll={false}>
				<div
					style={{
						height: "100%",
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						flexDirection: "column",
					}}
				>
					<IonList>
						<IonListHeader lines="full" style={{ display: "flex", justifyContent: "space-between" }}>
							<IonLabel data-testid="formRecordHeader"> {i18n.format(t("forms"), "capitalize")}</IonLabel>
							<IonLabel style={{ color: "var(--ion-color-medium-tint)", textAlign: "end" }}>
								{i18n.format(t("completitionStatus"), "capitalize")}
							</IonLabel>
						</IonListHeader>
						{state.loading && (
							<IonItem lines="none">
								<IonSpinner name="lines-sharp-small" style={{ margin: "auto" }} />
							</IonItem>
						)}
						{state.records.length === 0 && !state.loading && (
							<IonItem lines="full">
								<IonText style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
									{i18n.format(t("sync_records_msg"), "capitalize")}
								</IonText>
							</IonItem>
						)}
						{state.records.map((record) => {
							const form = state.forms.find((form) => form.id === record.form_id);
							const deliveryStatus = record.delivery_status;
							if (!form) return <></>;
							if (
								state.asset?.status?.tipo_ite === "Básica" &&
								(form.id.includes("eficiencia-energetica") || form.id.includes("accesibilidad"))
							) {
								return <></>;
							}
							return (
								<IonItem
									key={`${record.id}`}
									button={true}
									detail={true}
									routerLink={`/${projectRef}/${assetId}/${record.id}`}
									routerDirection="forward"
								>
									<IonLabel
										data-testid="formRecordLabel"
										className="ion-text-wrap"
										style={{ display: "flex", alignItems: "center" }}
									>
										{record.name}
									</IonLabel>
									{state.asset && (deliveryStatus === "pendingValidation" || deliveryStatus === "validated") && (
										<IonIcon icon={lock} size="small" style={{ marginRight: "1rem" }} />
									)}
									<CompletitionStatusIcon
										record={record}
										form={form}
										style={{ marginRight: "1rem" }}
										assetId={assetId}
									/>
								</IonItem>
							);
						})}
						{isOnline && projectRef === "adif-ite" && <LinkList assetId={assetId} />}
						{state.asset?.status?.status === "discarded" ? (
							<>
								<IonItem lines="none">
									<IonLabel style={{ color: "gray" }}>
										{i18n.format(t("delivery_comment_label"), "capitalize")}
									</IonLabel>
								</IonItem>
								<IonItem
									lines="none"
									style={{
										margin: "0 1rem 1rem 1rem",
										border: "solid 1px var(--ion-color-gray200)",
										padding: "0.5rem 1rem",
										marginBottom: "1rem",
										borderRadius: "0.375rem",
										backgroundColor: "white",
										height: "9.75rem",
										overflowY: "auto",
									}}
								>
									<IonLabel>{state.asset?.status?.comment}</IonLabel>
								</IonItem>
							</>
						) : (
							<></>
						)}
					</IonList>
					<div style={{ paddingBottom: "2rem" }}>
						<DeliveryStatusButtonList
							records={state.records || []}
							asset={state.asset}
							assetSyncState={state.assetSyncState}
							hasModelBim={hasModelBim}
							setOpenConfirmModal={setOpenConfirmModal}
							setOpenConfirmBIMModelModal={setOpenConfirmBIMModelModal}
							setSyncOpen={setSyncOpen}
						/>
					</div>
				</div>
				<ConfirmDeliveryModal
					isOpen={isOpenConfirmModal}
					isIteBasica={state.asset?.status?.tipo_ite === "Básica"}
					onButtonClick={async () => {
						const records = await FormRecord.byAssetId(assetId);
						await updateStatus(records.map((r) => ({ id: r.id, status: "pendingValidation" })));
						setOpenConfirmModal(false);
						await dispatch(performBackgroundSyncThunk({ projectRef, assetId }));
					}}
					onClose={() => {
						setOpenConfirmModal(false);
					}}
				/>
				<ConfirmBIMModelDeliveryModal
					isOpen={isOpenConfirmBIMModelModal}
					bimModelDeliverMessage={bimModelDeliverMessage}
					onButtonClick={async () => {
						try {
							await putBIMModelDelivered({ assetId: assetId, delivered: true });
							setOpenConfirmBIMModelModal(false);
							setHasModelBim(true);
							await dispatch(performBackgroundSyncThunk({ projectRef, assetId }));
						} catch (err) {
							console.log(err);
							setBimModelDeliverMessage(i18n.format(t("delivery_bim_model_error"), "capitalize"));
						}
					}}
					onClose={() => {
						setOpenConfirmBIMModelModal(false);
						setBimModelDeliverMessage(undefined);
					}}
				/>
				<SyncModal
					isOpen={isOpenSyncModal}
					projectRef={state.project?.ref || ""}
					title={state.asset?.name || ""}
					onClose={() => {
						setOpenSyncModal(false);
					}}
				/>
			</IonContent>
			{isOnline && (
				<SyncModal
					isOpen={syncOpen}
					projectRef={projectRef}
					assetId={assetId}
					title={state.asset?.name || ""}
					onClose={() => {
						setSyncOpen(false);
					}}
				/>
			)}
		</IonPage>
	);
};

export default RecordsView;
