import React from "react";

interface IProps {
	type: "nec" | "nee" | "nae" | "ncn"
	value: number
}

export const ITEResultIndicator: React.FC<IProps> = (props) => {
	const { value, type } = props;

	const getLabel = (type: string, value: number) => {
		if (type === "nec") return (
			<React.Fragment>
				<span>NC</span>
				<sub>E</sub>
				<span>{value}</span>
			</React.Fragment>
		)
		if (type === "nee") return (
			<React.Fragment>
				<span>NE</span>
				<sub>E</sub>
				<span>{value}</span>
			</React.Fragment>
		)
		if (type === "nae") return (
			<React.Fragment>
				<span>NA</span>
				<sub>E</sub>
				<span>{value}</span>
			</React.Fragment>
		)
		if (type === "ncn") return (
			<React.Fragment>
				<span>NCN</span>
				<sub>E</sub>
				<span>{value}</span>
			</React.Fragment>
		)
	}

	const maxIndiceLength = (type: string) => {
		if (type.startsWith("na") || type.startsWith("ncn")) {
			return 3;
		} else {
			return 4;
		}
	};

	const statusColorSelector = (value: number, maxValue: number) => {
		const color =
			maxValue === 4
				? value === 4
					? "#FFD9D9"
					: value === 3
						? "#FEEBC8"
						: value === 2
							? "#FFFAA8"
							: value === 1
								? "#CCF7C9"
								: "#EDF2F7"
				: value === 3
					? "#FFD9D9"
					: value === 2
						? "#FFFAA8"
						: value === 1
							? "#CCF7C9"
							: "#EDF2F7";
		return color;
	};

	const fontColorSelector = (value: number, maxValue: number) => {
		const color =
			maxValue === 4
				? value === 4
					? "#E61E28"
					: value === 3
						? "#DD6B20"
						: value === 2
							? "#BD8400"
							: value === 1
								? "#4A7F45"
								: "#718096"
				: value === 3
					? "#E61E28"
					: value === 2
						? "#BD8400"
						: value === 1
							? "#4A7F45"
							: "#718096";
		return color;
	};

	const statusBoxProps: React.CSSProperties = {
		borderRadius: "5px",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		width: "5rem",
		background: statusColorSelector(value, maxIndiceLength(type)),
		color: fontColorSelector(value, maxIndiceLength(type)),
	};
	return (
		<div style={statusBoxProps}>
			{getLabel(type, value)}
		</div>
	)
}
