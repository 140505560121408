import { DeliveryStatusOutput } from "interfaces/AssetStatus";
import { dbPromise } from "../services/idb";

export interface IRawAsset {
	id: string;
	project_ref: string;
	name: string;
	image?: string;
	location?: string;
	created_at: Date | string;
	is_deleted?: boolean;
	can_be_deleted?: boolean;
	status?: DeliveryStatusOutput;
}

export default class Asset implements IRawAsset {
	id: string;
	project_ref: string;
	name: string;
	image?: string;
	location?: string;
	created_at: Date;
	is_deleted: boolean;
	can_be_deleted: boolean;
	status?: DeliveryStatusOutput;

	constructor(asset: IRawAsset) {
		this.id = asset.id;
		this.project_ref = asset.project_ref;
		this.name = asset.name;
		this.image = asset.image;
		this.location = asset.location;
		this.created_at = new Date(asset.created_at);
		this.is_deleted = asset.is_deleted ?? false;
		this.status = asset.status;
		this.can_be_deleted = asset.can_be_deleted ?? false;
	}

	static async getAllKeys() {
		return await (await dbPromise).getAllKeys("assets");
	}

	static async get(id: string, includeDeleted = false) {
		return await (await dbPromise).get("assets", id).then((r) => {
			if (r === undefined) throw new Error(`Key ${id} not found`);
			if (r.is_deleted && !includeDeleted)
				throw new Error(`Key ${id} is archived -marked as deleted- and thus, not readily retrievable`);
			return new Asset(r);
		});
	}

	static async getMany(ids: string[], includeDeleted = false) {
		const tx = (await dbPromise).transaction("assets", "readwrite");
		const store = tx.objectStore("assets");
		const data = await Promise.all(ids.map((id) => store.get(id)));
		await tx.done;
		if (includeDeleted) return data.filter((it): it is Asset => !!it);
		return data.filter((it): it is Asset => !!it && !it.is_deleted);
	}

	static async getAll(includeDeleted = false) {
		const data = await (await dbPromise).getAll("assets").then((r) => r.map((ri) => new Asset(ri)));
		if (includeDeleted) return data;
		return data.filter((it) => !it.is_deleted);
	}

	static async byProjectRef(project_ref: string, includeDeleted = false) {
		const data = await (await dbPromise)
			.getAllFromIndex("assets", "project_ref", project_ref)
			.then((r) => r.map((ri) => new Asset(ri)));
		if (includeDeleted) return data;
		return data.filter((it) => !it.is_deleted);
	}

	static async set(data: Asset) {
		await (await dbPromise).put("assets", data);
		return new Asset(data);
	}

	static async setMany(data: Asset[]) {
		const tx = (await dbPromise).transaction("assets", "readwrite");
		const store = tx.objectStore("assets");
		await Promise.all(data.map((it) => store.put(it)));
		await tx.done;
	}

	static async deleteMany(ids: string[]) {
		const data = await Asset.getMany(ids);
		await Asset.setMany(data.map((it) => new Asset({ ...it, is_deleted: true })));
	}

	delete() {
		return Asset.set(new Asset({ ...this, is_deleted: true }));
	}

	async hardDelete() {
		return (await dbPromise).delete("assets", this.id);
	}

	static async hardDeleteMany(ids: string[]) {
		const tx = (await dbPromise).transaction("assets", "readwrite");
		const store = tx.objectStore("assets");
		await Promise.all(ids.map((id) => store.delete(id)));
		await tx.done;
	}

	save() {
		return Asset.set(this);
	}
}
